import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { Popup } from 'vant'
import 'vant/lib/index.css'

import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
// import { use as localeUse } from 'element-plus/lib/locale'
// import lang from 'element-plus/lib/locale/lang/zh-cn'
 import Viewer from 'v-viewer'
 import 'viewerjs/dist/viewer.css'
 

import '@/style/element-variables.scss'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'


import api from './api';

const app = createApp(App);

// elementui 图标引入
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

console.log(api,'api')
app.use(store)
app.use(router)
app.use(Popup)
app.use(VXETable)
app.use(ElementPlus, {
  locale: zhCn,
})
// Viewer.setDefaults({
//   Options: {
//     'inline': true,
//     'button': true, //右上角按钮
//     "navbar": false, //底部缩略图
//     "title": true, //当前图片标题
//     "toolbar": true, //底部工具栏
//     "tooltip": false, //显示缩放百分比
//     "movable": true, //是否可以移动
//     "zoomable": true, //是否可以缩放
//     "rotatable": false, //是否可旋转
//     "scalable": false, //是否可翻转
//     "transition": true, //使用 CSS3 过度
//     "fullscreen": true, //播放时是否全屏
//     "keyboard": true, //是否支持键盘
//     "url": "data-source",
//   }
// })
app.use(Viewer, {
defaultOptions: {
    'inline': false,
    'button': true, //右上角按钮
    "navbar": false, //底部缩略图
    "title": true, //当前图片标题
    "toolbar": true, //底部工具栏
    "tooltip": false, //显示缩放百分比
    "movable": true, //是否可以移动
    "zoomable": true, //是否可以缩放
    "minZoomRatio": 0.5, //最小缩放比例
    "maxZoomRatio": 1.5, //最大缩放比例
    "rotatable": false, //是否可旋转
    "scalable": false, //是否可翻转
    "transition": true, //使用 CSS3 过度
    "fullscreen": true, //播放时是否全屏
    "keyboard": true ,//是否支持键盘
    "zIndex": 9999
  }
  })
// createApp(App).config.productionTip = false;
app.config.globalProperties.post = api.post;
app.config.globalProperties.get = api.get;
app.config.globalProperties.put = api.put;
app.mount('#app')
