import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
// import { createRouter, createWebHashHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
// import HomeView from '../views/atlas/index.vue'

const routes = [
  {
    path: '/',
    redirect: { name: 'Website' },
  },
  {
    path: '/atlas',
    name: 'IndexAtlas',
    component: () => import(/* webpackChunkName: "about" */ '../views/atlas/index.vue')
  },
  {
    path: '/home',
    name: 'Website',
    meta: {
      title: '首页',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/website/index.vue')
  },
  {
    path: '/legal-privacy',
    name: 'LegalPrivacy',
    meta: {
      title: '法律声明',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/legal-privacy/index.vue')
  },
  {
    path: '/articles/wechat',
    name: 'WeChat',
    meta: {
      title: '官方微信号',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/articles/wechat.vue')
  },
  {
    path: '/articles/douyin',
    name: 'DouYin',
    meta: {
      title: '官方抖音号',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/articles/douyin.vue')
  },
  {
    path: '/articles/kuaishou',
    name: 'KuaiShou',
    meta: {
      title: '官方快手号',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/articles/kuaishou.vue')
  },
  {
    path: '/articles/shipinhao',
    name: 'ShiPinHao',
    meta: {
      title: '官方视频号',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/articles/shipinhao.vue')
  },
  {
    path: '/articles/xiaohongshu',
    name: 'XiaoHongShu',
    meta: {
      title: '官方小红书号',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/articles/xiaohongshu.vue')
  },
  {
    path: '/stores',
    name: 'Stores',
    meta: {
      title: 'FRAE门店',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/stores/index.vue')
  },
  {
    path: '/stores/:id',
    name: 'StoreDetail',
    meta: {
      title: '门店详情',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/stores/detail.vue')
  },
  {
    path: '/',
    name: 'Service',
    meta: {
      title: '帮助',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/service/index.vue'),
    children: [
      {
       path: 'service-policy',
       component: () => import('@/views/service/components/policy/index.vue'),
       name: 'ServicePolicy',
       meta: { title: '服务政策' }
      },{
       path: 'service-policy/showerRoom',
       component: () => import('@/views/service/components/policy/showerRoom.vue'),
       name: 'ServicePolicyShowerRoom',
       meta: { title: '智能淋浴房服务政策' }
      },{
       path: 'service-policy/bathroomCabinet',
       component: () => import('@/views/service/components/policy/bathroomCabinet.vue'),
       name: 'ServicePolicyBathroomCabinet',
       meta: { title: '智能浴室柜服务政策' }
      },{
       path: 'service-policy/intelligentToilet',
       component: () => import('@/views/service/components/policy/intelligentToilet.vue'),
       name: 'ServicePolicyIntelligentToilet',
       meta: { title: '智能马桶服务政策' }
      },{
       path: 'service-policy/shower',
       component: () => import('@/views/service/components/policy/shower.vue'),
       name: 'ServicePolicyShower',
       meta: { title: '智能淋浴花洒服务政策' }
      },{
       path: 'service-policy/stopcock',
       component: () => import('@/views/service/components/policy/stopcock.vue'),
       name: 'ServicePolicyStopcock',
       meta: { title: '智能水龙头服务政策' }
      },{
       path: 'faq',
       component: () => import('@/views/service/components/faq/index.vue'),
       name: 'FaqIndex',
       meta: { title: '常见问题' }
      },{
       path: 'faq/:id',
       component: () => import('@/views/service/components/faq/list.vue'),
       name: 'FaqList',
       meta: { title: '常见问题列表' }
      },{
       path: 'faq/:theme/:id',
       component: () => import('@/views/service/components/faq/document.vue'),
       name: 'FaqDocument',
       meta: { title: '常见问题文档' }
      },{
       path: 'service-appointment',
       component: () => import('@/views/service/components/appointment/index.vue'),
       name: 'ServiceAppointment',
       meta: { title: '服务预约' }
      },{
       path: 'service-appointment/install',
       component: () => import('@/views/service/components/appointment/install.vue'),
       name: 'ServiceAppointmentInstall',
       meta: { title: '安装预约' }
      },{
       path: 'service-appointment/maintenance',
       component: () => import('@/views/service/components/appointment/maintenance.vue'),
       name: 'ServiceAppointmentMaintenance',
       meta: { title: '维修预约' }
      },{
       path: 'service-appointment/design',
       component: () => import('@/views/service/components/appointment/design.vue'),
       name: 'ServiceAppointmentDesign',
       meta: { title: '设计预约' }
      },{
       path: 'service-appointment/care',
       component: () => import('@/views/service/components/appointment/care.vue'),
       name: 'ServiceAppointmentCare',
       meta: { title: '保养预约' }
      },{
       path: 'service-orders',
       component: () => import('@/views/service/components/appointment/step.vue'),
       name: 'ServiceOrders',
       meta: { title: '服务进度查询' }
      },
      {
       path: 'service-orders/:id',
       component: () => import('@/views/service/components/appointment/stepDetail.vue'),
       name: 'ServiceOrderDetail',
       meta: { title: '服务单详情' }
      }
    ]
  },
  {
    path: '/',
    name: 'BusinessInvitation',
    meta: {
      title: '招商',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BusinessInvitation/index.vue'),
    children: [
      {
       path: 'join-application',
       component: () => import('@/views/BusinessInvitation/components/main/application.vue'),
       name: 'JoinApplication',
       meta: { title: '加盟申请' }
      }]
  },
  {
    path: '/products/:id',
    name: 'Product',
    meta: {
      title: '产品列表',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/atlas/index.vue'),
  },
  {
    path: '/products/:serieId/:id',
    name: 'productDetail',
    meta: {
      title: '产品详情',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/atlas/detail.vue'),
  },{
    path: '/wishlist',
    name: 'wishlist',
    meta: {
      title: '心愿清单',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/wishlist/index.vue'),
  },{
    path: '/cart',
    name: 'Cart',
    meta: {
      title: '购物袋',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/cart/index.vue'),
  },
  {
    path: '/notFound',
    name: 'NotFound',
    meta: {
      title: '数据更新中，敬请期待',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/notFound/index.vue'),
  },
]

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        // document.title = to.meta.title
        document.title = to.meta.title + ' | 福瑞官网'
    }
    next();
});
export default router
