<template>
  <!-- <nav> -->
      <router-view/>
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> -->
  <!-- </nav> -->
  
</template>
<script>
    export default {
        name: 'App',
        watch: {
            '$route'(val){
                window.scrollTo(0, 0)
            }
        }
    }
</script>
<style lang="less">
    @import url('@/assets/fonts/index.css');
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
 font-family: SourceHanSansCN-Light;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #19110b;
  font-size: 14px;
}

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
