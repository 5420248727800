/**
 * @author czyyyyy
 *
 */
// import { Message } from 'view-design';
import axios from 'axios';
// import showStatus from '@/utils/message';
/*import OSS from 'ali-oss'

//如此就初始化了一个OSS的客户端，通过这个客户端可以实现上传，下载，预览等等相关的需求
export const client = new OSS({
    region: 'oss-cn-hangzhou', //这些都是你们公司在购买阿里云的时候设定的
    accessKeyId: "LTAI0U69dHJgjWfZ", //这些都是你们公司在购买阿里云的时候设定的
    accessKeySecret: "RkObegaAdm8PwZ2lOBlEI4BJ3OiXak", //这些都是你们公司在购买阿里云的时候设定的
    bucket: "frae-pinpai",
    secure: true, //在 OSSClient 初始化时加上 secure:true 就是 https 传输了
});*/
console.log(process.env.VUE_APP_API_URL,'process.env.VUE_APP_API_URL')
const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    // baseURL: 'https://dc.frae.com/server/api/',
    timeout: 20000,
});


axios.defaults.baseURL = '/api'
    // axios.defaults.withCredentials = false;
    // axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';

// eslint-disable-next-line arrow-body-style
export const uploadFileRequest = () => {
    return axios({
        responseType: 'blob',
    });
};

export const c = console.log.bind(document);

// 设置默认请求头
// api.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
// api.defaults.headers['Csrf-Token'] = 'nocheck';
api.defaults.headers['Content-Type'] = 'application/json';
api.defaults.headers['withCredentials'] = true
    // api.defaults.headers['Access-Control-Allow-Origin'] = '*'
    // headers: {
    //   'Access-Control-Allow-Origin': '*',
    // },
    // api.defaults.headers['Content-Type'] = 'application/json';
    /**
     * 请求拦截
     */
api.interceptors.request.use(
    (config) => {
        const configure = config;
        // window.console.log(this.get)
        console.log('进入', sessionStorage.getItem('Token'))
        if (!sessionStorage.getItem('Token')) {
            configure.headers.Authorization = localStorage.getItem('OrderToken');
        } else {
            configure.headers.Authorization = sessionStorage.getItem('Token');
        }
        // if(!localStorage.getItem('Token')) {
        //     configure.headers.Authorization = localStorage.getItem('OrderToken');
        // } else {
        //     configure.headers.Authorization = localStorage.getItem('Token');
        // }
        // configure.headers.Authorization = sessionStorage.getItem('access_token');
        // configure.headers.(Access-Control-Allow-Origin)/ = '*';
        // window.console.log(configure)
        return configure;
    },
    error => Promise.reject(error),
);

/**
 * 响应拦截
 */
api.interceptors.response.use((response) => {
    const { data } = response;
    if (response.status === 200 || response.statusText === 'OK') {
        return data;
    }
    return Promise.reject(new Error('错误'));
}, (error) => {
    // const message = showStatus(error.response.status);
    // Message.error({ content: message, duration: 5 });
    return Promise.reject(error.response);
});

// 处理 get 请求
const get = (url, params, config = {}) => api.get(url, {...config, params });
// const get = (url, params, config = {}) => api.post(url, params, config);
// 处理 post 请求
const post = (url, params, config = {}) => api.post(url, params, config);
// 处理 delete 请求，为了防止和关键词delete冲突，方法名定义为deletes
const deletes = (url, data, config = {}) => api.delete(url, {...config, data });
// 处理 put 请求
const put = (url, params, config = {}) => api.put(url, params, config);
// 处理 patch 请求
const patch = (url, params, config = {}) => api.patch(url, params, config);

// const console = console.log.bind(document);

export default {
    // client,
    // console,
    c,
    get,
    post,
    deletes,
    put,
    patch,
};